const HOST = '',// 服务器/API服务器域名,
    BASE = '',// API请求基础路径 比如 http://www.xxx.xxx/demo/test中的demo/test
    PBASE = "/",//静态页访问基础路径
    AUTH_BACK_URL = 'https://xxx/#/',//第三方授权跳转地址
    AUTH_URL = 'https://xx/authority/sso/login?encodeUrl=' + encodeURI(AUTH_BACK_URL),////第三方授权地址
    LOGOUT_URL = 'https://xxx/logout?reLoginUrl=' + encodeURI(AUTH_URL),//第三方退出登录地址
    HOME_URL_NAME = 'dashboard',//授权回调之后登陆成功跳转的首页路由名称
    SSO_HOST = 'https://xxx/'

export default {
    HOST,
    BASE,
    PBASE,
    AUTH_URL,
    AUTH_BACK_URL,
    HOME_URL_NAME,
    LOGOUT_URL,
    SSO_HOST,
};
