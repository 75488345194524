<template>

    <a-modal v-model:visible="visible"
             centered
             width="80%"
             @cancel="handleCancel"
             title="Manage your cookie preferences">
        <template #footer>
            <a-button type="primary"
                      key="acceptAllCookies"
                      @click="handleCookiesPreference('all')">Accept all cookies</a-button>
            <a-button key="rejectOptionalCookies"
                      type="primary"
                      @click="handleCookiesPreference('rejectOptional')">Reject optional cookies</a-button>
            <a-button key="saveCookiePreference"
                      type="primary"
                      @click="handleCookiesPreference('preference')">Save and close</a-button>
        </template>
        <article class="n-scroll-v"
                 style="height: calc(90vh - 90px);">

            <h2 class="n-c-base n-mt-3 n-mb-0">Cookies that are strictly necessary</h2>
            <p class="n-m-0">These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</p>

            <p>√ Always on &nbsp;<a style="color:blue;"
                   @click="showAlwaysOnCookie=!showAlwaysOnCookie">Cookie details&nbsp;{{ showAlwaysOnCookie?'-':'+' }}</a>
            </p>
            <template v-if="showAlwaysOnCookie">
                <div style="background-color: #eff6fb;margin-right: 20px;padding-left: 10px;padding-right: 10px;border-radius: 5px;">
                    <p>_clck | .intlpress.com | 1y</p>
                    <p>Set by clarity.ms and helps to system error tracking.</p>
                    <a-divider />
                    <p>_clsk | .intlpress.com | 1d</p>
                    <p>Set by clarity.ms and helps to system error tracking.</p>
                    <a-divider />
                    <p>mcdb | link.intlpress.com | 1y</p>
                    <p>This cookie is set by the cookie consent management system and stores information about the categories of cookies that the site visitor has given consent to.</p>
                    <a-divider />
                    <p>_pk_id.* | link.intlpress.com | 1y</p>
                    <p>This cookie is used for counter tracking.</p>
                    <a-divider />
                    <p>_pk_ses.* | link.intlpress.com | 1d</p>
                    <p>This cookie is used for counter tracking.</p>
                    <a-divider />
                    <p>__stripe_* | link.intlpress.com | 1y</p>
                    <p>Set by stripe.com and used to identify your payment transaction.</p>
                    <a-divider />
                    <p>sl-session | link.intlpress.com | 1d</p>
                    <p>Maintain the session state.</p>
                    <a-divider />
                    <p>portal-access-token | link.intlpress.com | Session</p>
                    <p>Identifies our user's session in our systems to enable both institutional and individual access.</p>
                    <a-divider />
                    <p>portal-refresh-token | link.intlpress.com | Session</p>
                    <p>Identifies our user's session in our systems to enable both institutional and individual access.</p>
                </div>
            </template>
            <a-divider />

            <h2 class="n-c-base n-mt-3 n-mb-0">Cookies that measure website use</h2>
            <p class="n-m-0">These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. We may also recommend content to you based on your website activity. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.</p>

            <p> <a-switch v-model:checked="cookieManage.measureWebsite"
                          size="small" /> &nbsp;<a style="color:blue;"
                   @click="showMeasureWebsiteCookie=!showMeasureWebsiteCookie">Cookie details&nbsp;{{ showMeasureWebsiteCookie?'-':'+' }}</a>
            </p>
            <template v-if="showMeasureWebsiteCookie">
                <div style="background-color: #eff6fb;margin-right: 20px;padding-left: 10px;padding-right: 10px;border-radius: 5px;">
                    <p>_ga | intlpress.com</p>
                    <p>This cookie is set by Google Analytics and helps us count how many people have visited our site by remembering if you have visited before.</p>
                    <a-divider />
                    <p>_ga_* | intlpress.com | 2y</p>
                    <p>Contains a randomly generated user ID. Using this ID, Google Analytics can recognize returning users on this website and combine data from previous visits.</p>
                    <a-divider />
                    <p>_gat_UA- | intlpress.com | 2y</p>
                    <p>This is a pattern type cookie set by Google Analytics, where the pattern element on the name contains the unique identity number of the account or website it relates to. It appears to be a variation of the _gat cookie which is used to limit the amount of data recorded by Google on high traffic volume websites.</p>
                </div>
            </template>
            <a-divider />
        </article>
    </a-modal>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';
    import nui from '@/assets/script';
    export default {
        name: 'ManagerCookie',
        props: {
            visible: {
                type: Boolean,
                default: false,
                required: true,
            },
        },
        computed: {
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                showAlwaysOnCookie: false,
                showMeasureWebsiteCookie: false,
                cookieManage: {
                    measureWebsite: false
                }
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),
            NMounted () {
                let mcdbString = this.getCookie("mcdb") || "{}";
                let mcdb = JSON.parse(mcdbString);

                if (mcdb == undefined || mcdb.measureWebsite == undefined) {
                    mcdb = { measureWebsite: true };
                }
                this.cookieManage.measureWebsite = mcdb.measureWebsite;
            },
            handleCancel () {
                this.$emit('update:visible', false); // 通知父组件更新 visible
                this.$emit('closeHeaderCookieInfo'); // 通知父组件closeHeaderCookieInfo
            },
            handleCookiesPreference (value) {
                value = value || 'all';

                switch (value) {
                    case 'all':
                        this.cookieManage.measureWebsite = true;
                        break;

                    case 'rejectOptional':
                        this.cookieManage.measureWebsite = false;
                        break;

                    case 'preference':
                        break;
                }
                this.setCookie("mcdb", JSON.stringify(this.cookieManage), 365);
                this.handleCancel();
            }
        }),
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
</style>